var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleVisibilityChange),expression:"handleVisibilityChange"}],staticClass:"vue-recycle-scroller",class:{
    ready: _vm.ready,
    'page-mode': _vm.pageMode,
    [`direction-${_vm.direction}`]: true,
  },on:{"&scroll":function($event){return _vm.handleScroll.apply(null, arguments)}}},[(_vm.$slots.before)?_c('div',{staticClass:"vue-recycle-scroller__slot"},[_vm._t("before")],2):_vm._e(),_c('div',{ref:"wrapper",staticClass:"vue-recycle-scroller__item-wrapper",style:({
      [_vm.direction === 'vertical' ? 'minHeight' : 'minWidth']: _vm.totalSize + 'px',
    })},_vm._l((_vm.pool),function(view){return _c('div',{key:view.nr.id,staticClass:"vue-recycle-scroller__item-view",class:{ hover: _vm.hoverKey === view.nr.key },style:(_vm.ready
          ? {
              transform: `translate${_vm.direction === 'vertical' ? 'Y' : 'X'}(${
                view.position
              }px)`,
            }
          : null),on:{"mouseenter":function($event){_vm.hoverKey = view.nr.key},"mouseleave":function($event){_vm.hoverKey = null}}},[_vm._t("default",null,{"item":view.item,"index":view.nr.index,"active":view.nr.used})],2)}),0),(_vm.$slots.after)?_c('div',{staticClass:"vue-recycle-scroller__slot"},[_vm._t("after")],2):_vm._e(),_c('ResizeObserver',{on:{"notify":_vm.handleResize}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }