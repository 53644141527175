import { render, staticRenderFns } from "./VideoDetailHeader.vue?vue&type=template&id=2644a850&scoped=true"
import script from "./VideoDetailHeader.vue?vue&type=script&lang=js"
export * from "./VideoDetailHeader.vue?vue&type=script&lang=js"
import style0 from "./VideoDetailHeader.vue?vue&type=style&index=0&id=2644a850&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2644a850",
  null
  
)

export default component.exports