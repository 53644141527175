var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{staticClass:"avatar"},[_c('app-avatar',{attrs:{"id":"navbarAvatar","size":"small","picture":_vm.user.profilePhotoURL,"name":_vm.name,"tabindex":"-1"}}),_c('b-popover',{ref:"profilePopover",attrs:{"target":"navbarAvatar","placement":"leftbottom","triggers":"click blur"}},[_c('div',[_c('div',{directives:[{name:"router-link",rawName:"v-router-link",value:({
          name: 'UProfile',
          params: { username: _vm.user.username.replace('@', '') },
        }),expression:"{\n          name: 'UProfile',\n          params: { username: user.username.replace('@', '') },\n        }"}],staticClass:"profile-menu-header cursor-pointer",on:{"click":_vm.closeProfilePopover}},[_c('app-avatar',{staticClass:"profile-menu-avatar",attrs:{"picture":_vm.user.profilePhotoURL,"name":_vm.name,"variant":"primary","size":"small"}}),_c('div',[_c('div',{staticClass:"cs-textstyle-paragraph-bold"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"ch-textstyle-paragraph-extrasmall"},[_c('app-username',{attrs:{"user":_vm.user}})],1)])],1),_c('div',{staticClass:"profile-menu-body"},[_c('div',{directives:[{name:"router-link",rawName:"v-router-link",value:({
            name: 'UProfile',
            params: { username: _vm.user.username.replace('@', '') },
          }),expression:"{\n            name: 'UProfile',\n            params: { username: user.username.replace('@', '') },\n          }"}],staticClass:"profile-menu-item cursor-pointer",on:{"click":_vm.closeProfilePopover}},[_c('i',{staticClass:"ch-icons-user"}),_vm._v(" View Profile ")]),_c('div',{directives:[{name:"router-link",rawName:"v-router-link",value:({ name: 'Settings' }),expression:"{ name: 'Settings' }"}],staticClass:"profile-menu-item cursor-pointer",on:{"click":_vm.closeProfilePopover}},[_c('i',{staticClass:"cs-icons-settings"}),_vm._v(" Settings ")]),_c('div',{staticClass:"profile-menu-item cursor-pointer",on:{"click":function($event){return _vm.reportBug()}}},[_c('i',{staticClass:"cs-icons-help"}),_vm._v(" Report a Bug ")]),_c('div',{staticClass:"profile-menu-item",on:{"click":_vm.logout}},[_c('i',{staticClass:"ch-icons-logout"}),_vm._v(" Sign Out ")])])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }