import { render, staticRenderFns } from "./ConversationMessageContent.vue?vue&type=template&id=60229d62&scoped=true"
import script from "./ConversationMessageContent.vue?vue&type=script&lang=js"
export * from "./ConversationMessageContent.vue?vue&type=script&lang=js"
import style0 from "./ConversationMessageContent.vue?vue&type=style&index=0&id=60229d62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60229d62",
  null
  
)

export default component.exports