var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onboarding password-form"},[(_vm.showFinalForm)?_c('app-final-form',{attrs:{"draft-user":_vm.draftUser,"token":_vm.token}}):_vm._e(),(!_vm.showFinalForm)?_c('div',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.continueToPhonePage.apply(null, arguments)}}},[_c('div',{staticClass:"password-form__input-pass"},[_c('cs-input',{attrs:{"label":"Password","required":"","type":_vm.passwordType},on:{"input":_vm.validatePass},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('cs-button',{staticClass:"password-form__hide-icon",attrs:{"variant":"default","fill":"clear","size":"small"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('i',{class:_vm.showPassword ? 'ch-icons-hide-password' : 'ch-icons-show-password'})])],1),_c('div',{staticClass:"password-form__input-pass"},[_c('cs-input',{attrs:{"label":"Confirm Password","required":"","type":_vm.confirmPasswordType},on:{"input":_vm.checkMismatch},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('cs-button',{staticClass:"password-form__hide-icon",attrs:{"variant":"default","fill":"clear","size":"small"},on:{"click":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}}},[_c('i',{class:_vm.showConfirmPassword
              ? 'ch-icons-hide-password'
              : 'ch-icons-show-password'})])],1),_c('div',{staticClass:"password-form__requirements-info"},[_c('p',{staticClass:"password-form__requirements"},[_vm._v("Requirements")]),_c('p',{class:_vm.has_minimum_lenth
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Must have a minimum of 8 and maximum of 20 characters ")]),_c('p',{class:_vm.has_number
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Must have at least one number ")]),_c('p',{class:_vm.has_lowercase
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Must contain one lowercase letter ")]),_c('p',{class:_vm.has_uppercase
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Must contain one uppercase letter ")]),_c('p',{class:_vm.has_special
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Must contain one special symbol ")]),_c('p',{class:!_vm.has_no_special
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Must not contain >, < and ? special symbol ")]),_c('p',{class:_vm.has_match_password
            ? 'password-form__requirement_success'
            : 'password-form__requirement'},[_c('i',{staticClass:"cs-icons-check-success"}),_vm._v(" Password doesn't match with Confirm Password ")])]),_c('div',{staticClass:"continue-button-container"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"size":"invisible","sitekey":_vm.sitekey},on:{"verify":_vm.continueToPhonePage,"expired":_vm.onCaptchaExpired}},[_c('cs-button',{attrs:{"size":"large","variant":"primary","disabled":!_vm.valid}},[_vm._v(" Continue ")])],1)],1),(!_vm.isMeeting)?_c('div',{staticClass:"password-form__back-button__container"},[_c('div',{staticClass:"password-form__backbutton"},[_c('div',{staticClass:"password-form__back"},[_c('cs-button',{staticClass:"password-form__back-button",attrs:{"fill":"clear","to":"usernameform"}},[_vm._v(" Back ")])],1),_c('div',{staticClass:"password-form__backtologin"},[_c('cs-button',{staticClass:"signup-form__back-button",attrs:{"fill":"clear","to":"/onboarding/"}},[_vm._v(" Back to Login ")])],1)])]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }