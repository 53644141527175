import { render, staticRenderFns } from "./PostComposer.vue?vue&type=template&id=37f1d238&scoped=true"
import script from "./PostComposer.vue?vue&type=script&lang=js"
export * from "./PostComposer.vue?vue&type=script&lang=js"
import style0 from "./PostComposer.vue?vue&type=style&index=0&id=37f1d238&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f1d238",
  null
  
)

export default component.exports