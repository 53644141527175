var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.user)?_c('app-spinner'):_vm._e(),(
      _vm.user &&
      !_vm.user.channelName &&
      !_vm.user.videoPosts.count &&
      _vm.isLogged &&
      !_vm.user.myRelationship.isBlocked
    )?_c('div',[_c('div',{staticClass:"create-channel-placeholder"},[_c('img',{staticClass:"profile-banner",attrs:{"src":require('@/assets/images/placeholders/profile-banner.png')}}),_c('div',{staticClass:"create-channel-placeholder-content"},[_c('div',{staticClass:"cs-textstyle-item-heading"},[_vm._v("Create a CloutHub Channel")]),_c('div',{staticClass:"mt-4"},[_vm._v(" It looks like your channel isn't set up yet. Create your channel in 6 easy steps so you can share your videos to your friends, followers and groups! ")]),_c('div',{staticClass:"mt-4"},[_c('cs-button',{on:{"click":function($event){return _vm.createChannel()}}},[_vm._v("+ Create Channel")])],1)])])]):_vm._e(),(
      _vm.user &&
      (_vm.user.channelName || _vm.user.videoPosts.count || !_vm.isLogged) &&
      !_vm.user.myRelationship.isBlocked
    )?_c('div',[_c('app-video-profile-header',{attrs:{"user":_vm.user},on:{"edit":function($event){return _vm.editChannel()},"afterSubscribeClick":function($event){return _vm.changeReloadUserFlag()},"edit-channel-cover":_vm.openChannelCoverModal,"refresh":_vm.refresh}}),(_vm.videos)?_c('app-video-list',{staticClass:"mt-4",attrs:{"videos":_vm.videos},on:{"reloadVideosList":function($event){return _vm.reloadVideosList()}}}):_vm._e(),_c('app-infinite-simple',{attrs:{"query":_vm.$apollo.queries.videos,"sort-key":"createdAt"}})],1):_vm._e(),(!!_vm.isLogged)?[_c('app-edit-channel-modal',{attrs:{"user":_vm.user,"show":_vm.showEditChannelModal},on:{"close":function($event){_vm.showEditChannelModal = false}}}),_c('app-create-channel-modal',{attrs:{"user":_vm.user,"show":_vm.showCreateChannelModal},on:{"close":function($event){_vm.showCreateChannelModal = false}}}),(_vm.showCoverModal)?_c('app-channel-cover-modal',{attrs:{"show-cover-modal":_vm.showCoverModal,"user-id":_vm.user.id,"channel-name":_vm.user.channelName,"cover-image":_vm.user.channelCover ? _vm.user.channelCover : null,"from-channel-header":""},on:{"~close-cover-modal":function($event){return _vm.closeChannelCoverModal.apply(null, arguments)}}}):_vm._e()]:_vm._e(),(_vm.user && _vm.user.myRelationship.isBlocked)?_c('b-container',{staticClass:"px-0",attrs:{"fluid":"xl"}},[_c('app-blocked-user-profile',{attrs:{"user":_vm.user},on:{"unblock":_vm.unblock}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }