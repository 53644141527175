import { render, staticRenderFns } from "./FollowCardSegment.vue?vue&type=template&id=24da44aa&scoped=true"
import script from "./FollowCardSegment.vue?vue&type=script&lang=js"
export * from "./FollowCardSegment.vue?vue&type=script&lang=js"
import style0 from "./FollowCardSegment.vue?vue&type=style&index=0&id=24da44aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24da44aa",
  null
  
)

export default component.exports