import { render, staticRenderFns } from "./ReportModal.vue?vue&type=template&id=0247a04b&scoped=true&lang=html"
import script from "./ReportModal.vue?vue&type=script&lang=js"
export * from "./ReportModal.vue?vue&type=script&lang=js"
import style0 from "./ReportModal.vue?vue&type=style&index=0&id=0247a04b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0247a04b",
  null
  
)

export default component.exports